import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import BinUploads from 'wms/bins/bins';

export default function BinsContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div className="bg-background h-full overflow-auto scrollbar">
          <Router>
            <BinUploads path="/wms/bins" />
          </Router>
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
